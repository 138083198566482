<template>
  <div class="box">
    <div v-if="!isHidden">
      <div class="file is-medium is-boxed is-centered">
        <label class="file-label">
          <input class="file-input" type="file" ref="file" accept="text/csv" v-on:change="onFileChange">
          <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">{{ file.name }}</span>
        </span>
        </label>
      </div>
      <div class="field is-grouped is-grouped-centered mt-3" v-if="file.size">
        <p class="control">
          <a class="button is-link"
             @click="uploadFile"
             :class="isButtonLoading ? 'is-loading' : ''">
            Завантажити
          </a>
        </p>
      </div>
    </div>

    <div v-if="ordersList.length">
      <div v-for="(order, index) in ordersList" :key="index" class="mb-5">
        <PromOrder
            :products-prop="order.products"
            :order-id-prop="order.orderId"
            :discount-prop="order.discount"
            :order-total-price-prop="order.orderTotalPrice"
            :cash-prop="order.cash"
            :cashless-prop="order.cashless"/>
      </div>
    </div>
  </div>
</template>

<script>
import _first from 'lodash/first';

import { UtilsService } from "@/modules/utils";
import { Constants } from "@/constants";
import PromOrder from "@/components/orders/PromOrder.vue";

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

const toastPosition = 'bottom-right';
const toastTimeout = 5000;

export default {
  name: "ImportTab",
  components: {
    PromOrder,
  },
  data() {
    return {
      file: {
        name: '*.csv файл'
      },
      ordersList: [],
      currency: 'грн',

      isButtonLoading: false,
      isHidden: false,
    }
  },
  methods: {
    onFileChange() {
      const file = _first(this.$refs.file.files);
      if (file) {
        if (file.type !== "text/csv") {
          createToast('Оберіть csv файл', {
            type: 'warning',
            position: toastPosition,
            timeout: toastTimeout
          })
          return;
        }
        this.file = file;
        return;
      }
      createToast('Помилка у виборі файлу, спробуйте ще раз', {
        type: 'warning',
        position: toastPosition,
        timeout: toastTimeout
      })
    },
    async uploadFile() {
      const checkboxLogin = localStorage.getItem('checkboxLogin');
      const checkboxPassword = localStorage.getItem('checkboxPassword');
      if (!checkboxLogin || !checkboxPassword) {
        createToast('Будь ласка, введіть Checkbox логін та пароль в налаштуваннях', {
          type: 'warning',
          position: toastPosition,
          timeout: toastTimeout
        })
        return;
      }

      const promToken = localStorage.getItem('promToken');
      if (!promToken) {
        createToast('Введіть Prom token в налаштуваннях', {
          type: 'warning',
          position: toastPosition,
          timeout: toastTimeout
        })
        return;
      }
      try {
        this.isButtonLoading = true;
        const data = new FormData()
        data.append('file', this.file);
        data.append('data', JSON.stringify({
          checkbox: {
            login: checkboxLogin,
            password: checkboxPassword,
          },
          prom: {
            token: promToken,
          }
        }))

        const response = await UtilsService.fetchJSON(`${Constants.backendUrl}${Constants.urls.uploadCheck}`, {
          method: 'POST',
          body: data
        })
        this.ordersList = response.list;
        this.isHidden = true;
      } catch (e) {
        createToast(e.message, {
          type: 'danger',
          position: Constants.toast.position,
          timeout: Constants.toast.timeout
        })
      } finally {
        this.isButtonLoading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
