export class UtilsService {
    static fetchJSON(...args) {
        return fetch(...args)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return res.text().then(text => {
                    throw new Error(text)
                })
            })
    }
}
