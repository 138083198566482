// const mode = 'develop';
const mode = 'production';

let urls;
let backendUrl;
if (mode === 'develop') {
    backendUrl = 'http://localhost:3000';
    urls = {
        getOrderById: '/prom/orders',
        createCheck: '/checkbox/check',
        uploadCheck: '/checkbox/upload',
    }
} else if (mode === 'production') {
    backendUrl = 'https://dev.prostocheck.com';
    urls = {
        getOrderById: '/api/prom/orders/order',
        createCheck: '/api/checkbox/create',
        uploadCheck: '/api/checkbox/upload',
    }
}

export const Constants = {
    urls,
    backendUrl,
    toast: {
        position: 'bottom-right',
        timeout: 10000,
    }
}
