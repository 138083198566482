<template>
  <TabGroup/>
</template>

<script>
import TabGroup from './components/TabGroup.vue';

export default {
  name: 'App',
  components: {
    TabGroup,
  }
}
</script>

<style>
</style>
