<template>
  <div class="box">
    <div class="field">
      <label class="label">Замовлення</label>
      <div class="control">
        <input class="input" type="text" placeholder="Номер замовлення" v-model="orderId">
      </div>
    </div>
    <div class="control">
      <button class="button is-link" @click="getOrder" :class="isButtonLoading ? 'is-loading' : ''">Підтвердити</button>
    </div>
    <div v-if="products.length > 0" class="block mt-5">
      <PromOrder
          :products-prop="products"
          :order-id-prop="orderIdToPass"
          :discount-prop="discount"
          :order-total-price-prop="orderTotalPrice"
          :cash-prop="cash"
          :cashless-prop="cashless"/>

    </div>
  </div>
</template>

<script>
import _trim from 'lodash/trim';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _split from 'lodash/split';

import { Constants } from "@/constants";
import { UtilsService } from "@/modules/utils";

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import PromOrder from "@/components/orders/PromOrder.vue";

export default {
  name: 'HomePage',
  components: {
    PromOrder,
  },
  data() {
    return {
      orderId: '', //226827718
      orderIdToPass: '',
      products: [],
      discount: 0,
      orderTotalPrice: 0,
      cash: 0,
      cashless: 0,
      isButtonLoading: false,
    };
  },
  methods: {
    async getOrder() {
      if (!this.orderId) {
        createToast('Введіть номер замовлення', {
          type: 'warning',
          position: Constants.toast.position,
          timeout: Constants.toast.timeout
        })
        return;
      }
      const promToken = localStorage.getItem('promToken');
      if (!promToken) {
        createToast('Введіть Prom token в налаштуваннях', {
          type: 'warning',
          position: Constants.toast.position,
          timeout: Constants.toast.timeout
        })
        return;
      }

      this.orderId = _join(_map(_split(_trim(this.orderId), ','), item => _trim(item)), ',');

      try {
        this.isButtonLoading = true;
        const order = await UtilsService.fetchJSON(`${Constants.backendUrl}${Constants.urls.getOrderById}?orderId=${this.orderId}&token=${promToken}`);
        this.products = order.products;
        this.orderTotalPrice = order.orderTotalPrice;
        this.discount = order.discount;
        this.cash = 0;
        this.cashless = 0;
        this.orderIdToPass = this.orderId;
      } catch (e) {
        createToast(e.message, {
          type: 'danger',
          position: Constants.toast.position,
          timeout: Constants.toast.timeout
        })
      } finally {
        this.isButtonLoading = false;
      }
    },
  },
};

</script>

<style scoped>

</style>
