<template>
  <article class="panel pb-3" :class="readyForCheckRuntime ? 'is-success' : 'is-warning'">
    <p class="panel-heading">Замовлення #{{ orderIdProp }}</p>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
      <tr>
        <th>Назва</th>
        <th>Кількість</th>
        <th>Ціна</th>
        <th>Сума</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(product, index) in products" :key="index">
        <td>{{ product.name }}</td>
        <td>{{ product.quantity }} шт</td>
        <td>{{ product.price }} {{ currency }}</td>
        <td>{{ product.totalPrice }} {{ currency }}</td>
      </tr>
      </tbody>
    </table>
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label">Сума замовлення</label>
      </div>
      <div class="field-body">
        <div class="field">
          <label>{{ orderTotalPriceWithoutDiscount }} {{ currency }}</label>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label">Виставлено замовнику</label>
      </div>
      <div class="field-body">
        <div class="field">
          <label>{{ orderTotalPriceProp }} {{ currency }}</label>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Сума, що надійшла</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input class="input" type="number" v-model.number="amountReceived" @change="onAmountReceivedChange">
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label">Знижка</label>
      </div>
      <div class="field-body">
        <div class="field">
          <label>{{ discount }} {{ currency }}</label>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Безготівка</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input class="input" type="number" v-model.number="cashless" @change="onCashlessChange"
                   :class="!readyForCheckRuntime ? 'is-danger' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Готівка</label>
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <input class="input" type="number" v-model.number="cash" @change="onCashChange"
                   :class="!readyForCheckRuntime ? 'is-danger' : ''">
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label">
        <!-- Left empty for spacing -->
      </div>
      <div class="field-body">
        <div class="field has-addons">
          <div class="control">
            <button class="button is-link"
                    v-if="readyForCheckRuntime && !isButtonHidden"
                    @click="issueCheck"
                    :class="isButtonLoading ? 'is-loading' : ''">
              Видати чек
            </button>
          </div>
          <div class="pdfIcon" v-if="checkId" @click="copyCheckId"></div>
        </div>
      </div>
    </div>
  </article>

</template>

<script>
import _round from "lodash/round";
import _cloneDeep from "lodash/cloneDeep";
import _sumBy from "lodash/sumBy";
import _find from "lodash/find";
import _first from "lodash/first";
import _assign from "lodash/assign";
import { createToast } from "mosha-vue-toastify";
import { UtilsService } from "@/modules/utils";
import { Constants } from "@/constants";

const toastPosition = 'bottom-right';
const toastTimeout = 10000;

export default {
  name: "PromOrder",
  props: {
    orderIdProp: {
      type: String,
      default: ''
    },
    productsProp: {
      type: Array,
      default: () => []
    },
    discountProp: {
      type: Number,
      default: 0,
    },
    orderTotalPriceProp: {
      type: Number,
      default: 0,
    },
    cashProp: {
      type: Number,
      default: 0,
    },
    cashlessProp: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      currency: 'грн',

      products: [],
      discount: 0,

      productsBackup: [],
      discountBackup: 0,

      orderTotalPriceWithoutDiscount: 0,
      amountReceived: 0,

      cash: 0,
      cashless: 0,

      checkId: '',

      isButtonLoading: false,
      isButtonHidden: false,
    }
  },
  watch: {
    orderIdProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    },
    productsProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    },
    discountProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    },
    orderTotalPriceProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    },
    cashProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    },
    cashlessProp: {
      handler() {
        this.recalculateAllFields();
      },
      deep: true,
      intermediate: true,
    }
  },
  mounted() {
    this.recalculateAllFields();
  },
  computed: {
    readyForCheckRuntime() {
      if (this.cash < 0) {
        return false;
      }
      if (this.cashless < 0) {
        return false;
      }
      return this.amountReceived === _round(this.cash + this.cashless, 2);
    }
  },
  methods: {
    recalculateAllFields() {
      this.products = this.productsProp;
      this.productsBackup = _cloneDeep(this.products);

      this.discount = this.discountProp;
      this.discountBackup = this.discount;

      this.orderTotalPriceWithoutDiscount = this.orderTotalPriceProp + this.discount;
      this.amountReceived = this.orderTotalPriceProp;

      this.cash = this.cashProp;
      this.cashless = this.cashlessProp;

      this.checkId = '';

      this.isButtonLoading = false;
      this.isButtonHidden = false;
    },
    onAmountReceivedChange() {
      this.amountReceived = _round(this.amountReceived, 2);
      if (this.amountReceived < this.orderTotalPriceWithoutDiscount) {
        // варіант 1
        this.discount = _round(this.orderTotalPriceWithoutDiscount - this.amountReceived, 2);
        this.products = _cloneDeep(this.productsBackup)
        return;
      }
      // варіант 2
      this.discount = 0;
      this.products = _cloneDeep(this.productsBackup)

      // пропорційно розбити переплату по товарах
      let diff = this.amountReceived - this.orderTotalPriceWithoutDiscount;
      this.products.forEach((product) => {
        this.calculateProductPrice(product, diff)
      });
      const sumTotalPrice = _round(_sumBy(this.products, 'totalPrice'), 2);
      if (this.amountReceived === sumTotalPrice) {
        return;
      }
      const totalPriceDiff = _round(this.amountReceived - sumTotalPrice, 2);
      // знайти продукт з quantity 1 і додати йому diff
      const targetSingleProduct = _find(this.products, { quantity: 1 });
      if (targetSingleProduct) {
        targetSingleProduct.price += totalPriceDiff;
        targetSingleProduct.totalPrice = _round(targetSingleProduct.price * targetSingleProduct.quantity, 2);
        return;
      }
      // взяти перший продукт і розділити на 1 та х-1, додати diff до того, що 1
      const targetMultipleProduct = _first(this.products);
      targetMultipleProduct.quantity -= 1;
      targetMultipleProduct.totalPrice = _round(targetMultipleProduct.price * targetMultipleProduct.quantity, 2);
      this.products.push(_assign({}, targetMultipleProduct, {
        quantity: 1,
        price: _round(targetMultipleProduct.price + totalPriceDiff, 2),
        totalPrice: _round(targetMultipleProduct.price + totalPriceDiff, 2)
      }));
    },
    onCashChange() {
      this.cash = _round(this.cash, 2);
      this.cashless = _round(this.amountReceived - this.cash, 2);
    },
    onCashlessChange() {
      this.cashless = _round(this.cashless, 2);
      this.cash = _round(this.amountReceived - this.cashless, 2);
    },
    calculateProductPrice(product, diff) {
      const productDiff = _round(product.price * 100 / this.orderTotalPriceWithoutDiscount * diff / 100, 2);
      product.price = _round(product.price + productDiff, 2);
      product.totalPrice = _round(product.price * product.quantity, 2);
    },
    async issueCheck() {
      const checkboxLogin = localStorage.getItem('checkboxLogin');
      const checkboxPassword = localStorage.getItem('checkboxPassword');
      if (!checkboxLogin || !checkboxPassword) {
        createToast('Будь ласка, введіть Checkbox логін та пароль в налаштуваннях', {
          type: 'warning',
          position: toastPosition,
          timeout: toastTimeout
        })
        return;
      }
      try {
        this.isButtonLoading = true;
        const checkboxCheck = await UtilsService.fetchJSON(`${Constants.backendUrl}${Constants.urls.createCheck}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            checkbox: {
              login: checkboxLogin,
              password: checkboxPassword,
            },
            products: this.products,
            discount: this.discount,
            amountReceived: this.amountReceived,
            cash: this.cash,
            cashless: this.cashless,
          })
        });
        this.checkId = checkboxCheck.id;
        createToast('Чек успішно створено', {
          type: 'success',
          position: toastPosition,
          timeout: toastTimeout
        });
        this.isButtonHidden = true;
      } catch (e) {
        createToast(`Помилка, чек не створено. ${e.message}`, {
          type: 'danger',
          position: toastPosition,
          timeout: toastTimeout
        })
      } finally {
        this.isButtonLoading = false;
      }
    },
    async copyCheckId() {
      await navigator.clipboard.writeText(`https://check.checkbox.ua/${this.checkId}`);
      createToast('Лінк скопійовано', {
        type: 'success',
        position: toastPosition,
        timeout: 5000
      });
    }

  }

}
</script>

<style scoped>
.pdfIcon {
  background-image: url("../../../public/pdf.jpeg");
  width: 35px;
  height: 42px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-top: -1px;
  cursor: pointer;
}
</style>
