<template>
  <div class="box">
    <div class="field">
      <label class="label">Prom token</label>
      <div class="control">
        <input class="input" type="text" placeholder="Prom token" v-model="promToken" @change="onPromTokenChange">
      </div>
    </div>
    <div class="field">
      <label class="label">Checkbox login</label>
      <div class="control">
        <input class="input" type="text" placeholder="Checkbox login" v-model="checkboxLogin"
               @change="onCheckboxLoginChange">
      </div>
    </div>
    <div class="field">
      <label class="label">Checkbox password</label>
      <div class="control">
        <input class="input" type="password" placeholder="Checkbox password" v-model="checkboxPassword"
               @change="onPromTokenChange">
      </div>
    </div>
    <div class="control">
      <button class="button is-link" @click="save">Зберегти</button>
    </div>
  </div>
</template>

<script>
import _trim from "lodash/trim";

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

const toastPosition = 'bottom-right';
const toastTimeout = 5000;

export default {
  name: "SettingsTab",
  data() {
    return {
      promToken: '',
      checkboxLogin: '',
      checkboxPassword: '',
    }
  },
  mounted() {
    if (localStorage.getItem('promToken')) {
      this.promToken = localStorage.getItem('promToken');
    }
    if (localStorage.getItem('checkboxLogin')) {
      this.checkboxLogin = localStorage.getItem('checkboxLogin');
    }
    if (localStorage.getItem('checkboxPassword')) {
      this.checkboxPassword = localStorage.getItem('checkboxPassword');
    }
  },
  watch: {
    promToken(newValue) {
      localStorage.setItem('promToken', newValue);
    },
    checkboxLogin(newValue) {
      localStorage.setItem('checkboxLogin', newValue);
    },
    checkboxPassword(newValue) {
      localStorage.setItem('checkboxPassword', newValue);
    }
  },
  methods: {
    onPromTokenChange() {
      this.promToken = _trim(this.promToken);
    },
    onCheckboxLoginChange() {
      this.checkboxLogin = _trim(this.checkboxLogin);
    },
    save() {
      createToast('Налаштування успішно збережено', {
        type: 'success',
        position: toastPosition,
        timeout: toastTimeout
      })
    }
  }

}
</script>

<style scoped>

</style>
