<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="(tab, index) in tabs" v-bind:key="index" :class="{ 'is-active': tab.isActive }">
          <a @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <div v-show="tabs[0].isActive">
        <HomePage/>
      </div>
      <div v-show="tabs[1].isActive">
        <ImportTab/>
      </div>
      <div v-show="tabs[2].isActive">
        <SettingsTab/>
      </div>
    </div>
  </div>
</template>

<script>
import HomePage from '@/components/HomePage.vue';
import SettingsTab from '@/components/tabs/SettingsTab.vue';
import ImportTab from "@/components/tabs/ImportTab.vue";

export default {
  components: {
    HomePage,
    SettingsTab,
    ImportTab
  },
  name: "TabGroup",
  data() {
    return {
      tabs: [
        {
          isActive: true,
          name: 'Створити чек'
        },
        {
          isActive: false,
          name: 'Імпорт файлу',
        },
        {
          isActive: false,
          name: 'Налаштування',
        },
      ]
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name);
      });
    }
  }
}
</script>

<style scoped>

</style>
